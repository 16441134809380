// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://prod.webdevelopmentsolution.net:3050',
  // imageUrl: 'https://prod.webdevelopmentsolution.net:3050'
  // baseUrl: 'https://prod.webdevelopmentsolution.net:6001',
  // imageUrl: 'https://prod.webdevelopmentsolution.net:6001',

  // new great base url
  baseUrl: 'https://api.apptunix.com/master/v1',
  imageUrl: 'https://api.apptunix.com/master/v1',
  TokenValue: 'greatAdminAccessToken',
  adminUrl:'https://order.quick-works.com/#/',
  email: '',
  password: '',
  // email: 'appdemo5494@gmail.com',
  // password: 'apptunix@live',
  // Image Url For Live
  // imageUrl: 'https://prod.webdevelopmentsolution.net:6003',
  // TokenValue:"greatAdminAccessToken",
  // googleMapKey: 'AIzaSyC7Ju90BCi9a01akYUWBvrEOFzwl8yxWZ8',
  // RememberMe:'greatAdmin',
  // ImgPlaceHolder:'assets/images/users/placeHolder.jpeg
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
